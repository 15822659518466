<template>
  <base-section
    id="k-d-a-book-banner"
    :class="`overflow-hidden ${strBgColor}`"
    :style="{ background: hexBgColor }"
    space="0"
  >
    <v-container class="my-10 my-sm-16 py-0 px-11 px-md-0">
      <v-row no-gutters>
        <v-col id="k-d-a-book-banner-content" cols="12" class="col-md-10">
          <div style="position: relative">
            <h1 class="kda-ts-40pt wt-extrabold">{{ m_strTitle }}</h1>

            <img :src="artSrc" style="position: absolute; left: -25px; top: -8px" />
          </div>
          <v-btn
            color="main-r-400"
            class="text-capitalize white--text mt-4 mt-md-8"
            :x-large="!g_bLowerBr"
            @click="navToConsultations"
          >
            {{ m_strButton }}
          </v-btn>
        </v-col>

        <v-col>
          <div class="background-img-container">
            <img :src="imageSrc" :class="g_bLowerBr ? 'bottom' : 'right'" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import TextClass from '@/mixins/text-class';

export default {
  name: 'SectionBookBanner',

  mixins: [TextClass, BaseBreakpoint],

  props: {
    strBackgorundColor: {
      type: String,
      default: 'divisi-light-blue'
    }
  },

  computed: {
    imageSrc() {
      return require('@/assets/logos/logo-kedatech-thumb.png');
    },
    artSrc() {
      return require('@/assets/photos/book-banner-art.png');
    },
    hexBgColor() {
      return this.$route?.meta?.hexBookColor;
    },
    strBgColor() {
      return !this.hexBgColor ? this.$route?.meta?.strBookColor ?? this.strBackgorundColor : '';
    },
    m_strTitle() {
      return this.$vuetify.lang.t('$vuetify.kda.common.book-banner.desc');
    },
    m_strButton() {
      return this.$vuetify.lang.t('$vuetify.kda.common.book-banner.btn');
    },
    navToTop() {
      return this.$route?.meta?.navToTop;
    }
  },

  methods: {
    navToConsultations() {
      window.gtag('event', 'click', {
        'event_label': 'Book A Chat'
      });

      if (this.navToTop) {
        this.$vuetify.goTo(0);
      } else {
        this.$router.push('/consultations');
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.background-img-container {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    opacity: 0.2;

    &.right {
      height: 458px;
      position: absolute;
      right: 0;
    }

    &.bottom {
      height: 400px;
      position: relative;
      bottom: -64px;
      left: -64px;
    }
  }
}

#k-d-a-book-banner-content {
  z-index: 1;
}
</style>
